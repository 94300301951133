@import '../../styles/_variables.sass';

#menu-admin{

    height: calc(100% - 65px);
    overflow-x: hidden;

    #account-name{
        span {
            display: inline-block;
            vertical-align: top;
        }

        span.user-photo {
            width: 40px;
            min-width: 40px;
            height: 40px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            border-radius: 50%;
            background: $orange;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;
            color: white;
            font-weight: bold;
            font-size: 18px;
            padding-top: 8px;
            text-align: center;
        }

        span.user-name {
            font-size: 16px;
            padding: 0 10px 0 8px;
            flex-grow: 1;
        }

        .admin-btn {
            font-size: 16px;
            padding: 0 10px 0 8px;
            flex-grow: 1;
            color: $blue;
        }

        span.user-name .text-profile {
            font-size: 14px;
            color: #8c8c8c;
        }

        span.user-name .user-status {
            font-size: 12px;
        }

          span.log-out {
            width: 17px;
            height: 23px;
            display: block;
            background: url('../../../public/images/icons/logout.png') no-repeat center 2px;
            background-size: 100%;
          }
    }

    .menu-items{
        .menu-item{
            width: 100%;
            text-transform: uppercase;
            a{
                text-decoration: none;
                display: block;
                font-size: 16px;
                padding: 10px;
                color: $black;
                &:hover{
                
                    background-color: $orange;
                    color:white;
                    &.level-2{
                        background-color: $orange2;
                    }
                }
                &.selected{
                    background-color: $orange;
                    color:white;

                    &.level-2{
                        background-color: $orange2;
                    }
                }

                &.back-btn{
                    color:white;
                    background-color: $blue;
                }
            }
        }
    }
}